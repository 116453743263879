import React, { useState, useEffect, useContext } from "react"

import { EnterRXWrapper } from "../RXComponent"
import ProductDetailsMobile from "../ProductDetailsMobile"
import ProductSlider from "../ProductSlider"
import NewCustomSelect from "../NewCustomSelect"
import NewButton from "../NewButton"
import { data } from "../PrescriptionRow/mockedData"
import { usePromo, promoStart, promoEnd } from "../../utils/usePromo"
import { selectDefaultValue } from "../../services/utils"
import scrollTo from "gatsby-plugin-smoothscroll"
import CartContext from "../../context/cartContext"
import RequiredMessage from "../RequiredMessage"
import { newRxContactsList } from "../ContactsPrescription/mockedData"
import PowerConfirmationModal from "../PowerConfirmationModal"
import WebpImage from "../WebpImage";
import ProductTag from "../ProductTag";
import {getHubblePricing, hubbleClassic} from "../../services/data";

export const getPowerOptions = (eye, isHydro, isSkyhy, noOneEye) => {
  const oneEyeValue = { value: 'oneEye', label: `I don't need ${eye} eye lenses` }

  switch (true) {
    case isHydro:
      return [...(noOneEye ? [] : [oneEyeValue]), ...data.hydroPower]
    case isSkyhy:
      return [...(noOneEye ? [] : [oneEyeValue]), ...data.skyhyPower]
    default:
      return [...(noOneEye ? [] : [oneEyeValue]), ...data.classicPower]
  }
}

const ContactsDetailsMobile = ({item, type, slides, handleGaClick, lenses, isHydro, isSkyhy, tagColor, tagText}) => {
  const isPromo = usePromo(promoStart, promoEnd)
  const { title, offPrice, price, percentOff } = item
  const { currentItem, order, setCurrentItem, setOrder } = useContext(CartContext)
  const hubblePricing = getHubblePricing()

  const [prescription, setPrescription] = useState({
    leftEye: {
      power: order?.prescription?.leftEye?.power,
      baseCurve: item.baseCurve,
      diameter: item.diameter,
    },
    rightEye: {
      power: order?.prescription?.rightEye?.power,
      baseCurve: item.baseCurve,
      diameter: item.diameter,
    },
  })
  const [rightPowerOptions] = useState(getPowerOptions('right', isHydro, isSkyhy))
  const [leftPowerOptions] = useState(getPowerOptions('left', isHydro, isSkyhy))
  const [rightPowerValue, setRightPowerValue] = useState(selectDefaultValue(rightPowerOptions, prescription?.rightEye?.power))
  const [leftPowerValue, setLeftPowerValue] = useState(selectDefaultValue(leftPowerOptions, prescription?.leftEye?.power))
  const [isEmptyValues, setIsEmptyValues] = useState(false)
  const [powerModalShow, setPowerModalShow] = useState(false)
  const [powerModalShowWasShown, setPowerModalShowWasShown] = useState(false)

  useEffect(() => {
    setPrescription({
      leftEye: {
        power: order?.prescription?.leftEye?.power,
        baseCurve: item.baseCurve,
        diameter: item.diameter,
      },
      rightEye: {
        power: order?.prescription?.rightEye?.power,
        baseCurve: item.baseCurve,
        diameter: item.diameter,
      },
    })

    if (!order?.prescription?.rightEye?.power) setRightPowerValue(null)
    if (!order?.prescription?.leftEye?.power) setLeftPowerValue(null)

    if (
      order?.prescription?.rightEye?.power === "oneEye" ||
      order?.prescription?.leftEye?.power === "oneEye"
    ) {
      setOrder({ ...order, isOneEye: true })
      return
    }
    setOrder({...order, isOneEye: false})
  }, [currentItem])

    useEffect(() => {
    const isEqualPowers = Math.sign(prescription.leftEye.power) === Math.sign(prescription.rightEye.power)
    if (isEqualPowers) setPowerModalShowWasShown(false)
    if ((prescription.leftEye.power && !isNaN(prescription.leftEye.power)) && (prescription.rightEye.power && !isNaN(prescription.rightEye.power)) && !isEqualPowers && !powerModalShowWasShown) {
      setPowerModalShow(true)
      setPowerModalShowWasShown(true)
    }
  }, [prescription])

  const handlePowerChange = (eye, value) => {
    if (eye === "rightEye") setRightPowerValue(selectDefaultValue(rightPowerOptions, value))
    if (eye === "leftEye") setLeftPowerValue(selectDefaultValue(leftPowerOptions, value))
    const isOneEye = value === 'oneEye'
    const newEye = { ...prescription[eye], power: value }
    const newPrescription = { ...prescription, [eye]: newEye }
    const newOrder = { ...order, prescription: newPrescription, isOneEye }
    setOrder(newOrder)
    setPrescription(newPrescription)
  }

  const handleBuyBtn = () => {
    if (!(leftPowerValue && rightPowerValue) || (prescription?.rightEye?.power === 'oneEye' && prescription?.leftEye?.power === 'oneEye')) {
      setIsEmptyValues(true)
      scrollTo(".price-container")
      return
    }
    const brand = isHydro ? "hydro" : isSkyhy ? "skyhy" : "classic"
    const contactsItem = newRxContactsList(hubblePricing)[0].options.filter(item =>
      item.title.toLowerCase().includes(brand)
    )[0]

    setIsEmptyValues(false)
    handleGaClick()
  }

  return (
    <div className="top-section mobile">
      <h1 className="text h3 heading">{item.title}</h1>
      <ProductSlider slides={slides} type={type} product={item} containImage centerImage  tagText={tagText} tagColor={tagColor} />


      <div className={isPromo ? "price-container promo" : "price-container"}>
        <h4 className="lenses-subheader">30 daily lenses</h4>
          {offPrice && title?.toLowerCase()?.includes("classic") && (
            <span className="text h7 price">
              <s>${price.toFixed(2)}</s> <b>${offPrice.toFixed(2)}</b> {hubbleClassic.trialPeriod}-day starter pack
            </span>
          )}

          {offPrice &&
            (title?.toLowerCase()?.includes("hydro") ||
            title?.toLowerCase()?.includes("skyhy")) && (
              <p className="text h7 price">
                <s>${price.toFixed(2)}/eye</s> <b>${offPrice.toFixed(2)}/eye</b>
                <span className="off-percent"><b>({percentOff}% off)</b> for new customers</span>
              </p>
            )}

        {!offPrice && (
          <span className="text h7 price">${price.toFixed(2)}</span>
        )}
      </div>

      {isPromo && (isHydro || isSkyhy) &&
        <div className="promo-limited-banner">
          <WebpImage className="promo-icon" fileName="Pages/Intake/Summer-Promo-Icon.svg" objectFit="contain"/>
          <p>limited time only<br/>now through june 4</p>
          <WebpImage className="promo-icon" fileName="Pages/Intake/Summer-Promo-Icon.svg" objectFit="contain"/>
        </div>
      }

      <div className="rx-wrapper">
        <EnterRXWrapper title="Enter Your Prescription" color="purple">
          <div className="power-row">
              <div className="select-container">
                <p className="title text h8 bulky ocean-blue">Right Eye (OD)</p>
                <NewCustomSelect
                  value={rightPowerValue}
                  name='power'
                  onChange={({ value }) => handlePowerChange("rightEye", value)}
                  options={rightPowerOptions}
                  placeholder={"Select"}
                  error={!prescription.rightEye.power && prescription.rightEye.power !== "oneEye" && isEmptyValues}
                />
              </div>
              <div className="select-container">
                <p className="title text h8 bulky ocean-blue">Left Eye (OS)</p>
                <NewCustomSelect
                  value={leftPowerValue}
                  name='power'
                  onChange={({ value }) => handlePowerChange("leftEye", value)}
                  options={leftPowerOptions}
                  placeholder={"Select"}
                  error={!prescription.leftEye.power && prescription.leftEye.power !== "oneEye" && isEmptyValues}
                />
              </div>
            </div>
          </EnterRXWrapper>
       </div>
      <NewButton
        label={"Continue to Checkout"}
        color={"purple"}
        onClick={() => handleBuyBtn()}
        disabled={lenses?.length !== 0}
        className={'mobile-only'}
        stickyScroll={true} />
      <ProductDetailsMobile item={item} type={type} hydro={true} onClick={() => handleGaClick()} />
      <PowerConfirmationModal modalShow={powerModalShow} setModalShow={setPowerModalShow} />
    </div>
  )
}

export default ContactsDetailsMobile
